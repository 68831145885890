export enum OfferingIntent {
  SHOW_DETAILS = 'showDetails',
  BOOK_OFFERING = 'bookOffering',
}

export enum FormFactor {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
  TABLET = 'Tablet',
}
