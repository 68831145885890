import { OfferingDomain } from './offering-domain';
import { OfferingDisplayOptions } from '../business-logic/offering-list-widget-display-options';
import {
  AlignmentOptions,
  DockLocationOptions,
  ImageAndTextRatioOptions,
  ImagePositionOptions,
  ImageResizeOptions,
  OfferingListLayoutOptions,
} from '../../SharedAppKeys/SettingsKeys';
import { PricingPlanInfo } from '@wix/bookings-uou-domain/dist/src/offerings/offering.dto';
import { ImageDto, OfferingType } from '@wix/bookings-uou-domain/dist/src';

export interface IOfferingViewModel {
  title?: string;
  tagLine?: string;
  duration?: string;
  price?: string;
  days?: string;
  startDate?: string;
  image?: ImageViewModel;
  action?: ActionViewModel;
  type?: OfferingType;
  pricingPlanInfo?: PricingPlanInfo;
  id: string;
  displayOnlyNoBookFlow: boolean;
  errorMessageTranslated?: string;
  dividerVisibility: boolean;
  textAlignment: AlignmentOptions;
  layout: OfferingListLayoutOptions;
  ratio: ImageAndTextRatioOptions;
  ratioFlipped: boolean;
  imagePosition: ImagePositionOptions;
}

export interface ImageViewModel {
  mediaItem: ImageDto;
  isVisible: boolean;
  imageResize: ImageResizeOptions;
  focalPoint: { x: number; y: number };
}

export interface ActionViewModel {
  type;
  text: string;
  isVisible: boolean;
  isLarge: boolean;
  isSecondary: boolean;
}

export const DEFAULT_FOCAL_POINT = { x: 50, y: 50 };

export const dockLocationToFocalPointMapper = {
  [DockLocationOptions.TOP]: { x: 50, y: 0 },
  [DockLocationOptions.TOP_RIGHT]: { x: 100, y: 0 },
  [DockLocationOptions.RIGHT]: { x: 100, y: 50 },
  [DockLocationOptions.BOTTOM_RIGHT]: { x: 100, y: 100 },
  [DockLocationOptions.BOTTOM]: { x: 50, y: 100 },
  [DockLocationOptions.BOTTOM_LEFT]: { x: 0, y: 100 },
  [DockLocationOptions.LEFT]: { x: 0, y: 50 },
  [DockLocationOptions.TOP_LEFT]: { x: 0, y: 0 },
  [DockLocationOptions.MIDDLE]: { x: 50, y: 50 },
};

export function getFocalPoint(
  dockLocation?: DockLocationOptions,
): { x: number; y: number } {
  return dockLocationToFocalPointMapper[dockLocation] || DEFAULT_FOCAL_POINT;
}

function getBookActionText(
  offeringDisplayOptions: OfferingDisplayOptions,
  formatter: Function,
) {
  return (
    offeringDisplayOptions.bookActionText ||
    formatter('info-card.design.button.book-it')
  );
}

function getNoBookActionText(
  offeringDisplayOptions: OfferingDisplayOptions,
  formatter: Function,
) {
  return (
    offeringDisplayOptions.noBookActionText ||
    formatter('info-card.design.button.more-info')
  );
}

function getImageResize(
  offeringDisplayOptions: OfferingDisplayOptions,
): ImageResizeOptions {
  return offeringDisplayOptions.layout === OfferingListLayoutOptions.OVERLAPPING
    ? ImageResizeOptions.CROP
    : offeringDisplayOptions.offeringImageResize;
}

export class OfferingViewModelFactory {
  static createOfferingViewModel(
    offeringDomain: OfferingDomain,
    offeringDisplayOptions: OfferingDisplayOptions,
    formatter: Function,
  ): IOfferingViewModel {
    const offeringTitleView = offeringDomain.name;
    const offeringDurationView =
      offeringDisplayOptions.isOfferingDurationVisible &&
      (offeringDomain.type === OfferingType.GROUP ||
        offeringDomain.type === OfferingType.INDIVIDUAL)
        ? offeringDomain.durationTextByFormat(formatter)
        : null;
    const offeringStartDateView =
      offeringDisplayOptions.isOfferingStartDateVisible &&
      offeringDomain.type === OfferingType.COURSE
        ? offeringDomain.durationTextByFormat(formatter)
        : null;
    const offeringPriceView = offeringDisplayOptions.isOfferingPriceVisible
      ? offeringDomain.priceText
      : null;
    const offeringDaysView =
      offeringDisplayOptions.isOfferingOfferedDaysVisible &&
      offeringDomain.type === OfferingType.GROUP
        ? offeringDomain.daysTextByFormat(formatter)
        : null;
    const offeringTagLineView =
      offeringDomain.tagLine && offeringDisplayOptions.isOfferingTagLineVisible
        ? offeringDomain.tagLine
        : null;
    const errorMessageTranslated = offeringDomain.errorMessageTranslation(
      formatter,
    );
    const offeringImageView = {
      mediaItem: offeringDomain.image,
      isVisible: offeringDisplayOptions.isOfferingImageVisible,
      imageResize: getImageResize(offeringDisplayOptions),
      focalPoint: getFocalPoint(
        offeringDisplayOptions.offeringImageDockLocation,
      ),
    };
    const offeringDividerView = offeringDisplayOptions.isOfferingDividerVisible;
    const offeringActionView: ActionViewModel = {
      isVisible: offeringDisplayOptions.isOfferingBookButtonVisible,
      type: offeringDisplayOptions.buttonStyle,
      text: offeringDomain.displayOnlyNoBookFlow
        ? getNoBookActionText(offeringDisplayOptions, formatter)
        : getBookActionText(offeringDisplayOptions, formatter),
      isLarge: offeringDisplayOptions.isLargeButton,
      isSecondary: offeringDisplayOptions.isSecondaryButton,
    };
    const offeringTextAlignment = offeringDisplayOptions.offeringTextAlignment;
    const offeringLayout = offeringDisplayOptions.layout;
    const offeringRatio = offeringDisplayOptions.ratio;
    const offeringRatioFlipped = offeringDisplayOptions.ratioFlipped;
    const offeringImagePosition = offeringDisplayOptions.imagePosition;

    //todo noa - add two components - days and start at !!!
    return {
      title: offeringTitleView,
      tagLine: offeringTagLineView,
      duration: offeringDurationView,
      price: offeringPriceView,
      days: offeringDaysView,
      startDate: offeringStartDateView,
      image: offeringImageView,
      action: offeringActionView,
      type: offeringDomain.type,
      pricingPlanInfo: offeringDomain.pricingPlanInfo,
      id: offeringDomain.id,
      displayOnlyNoBookFlow: offeringDomain.displayOnlyNoBookFlow,
      dividerVisibility: offeringDividerView,
      textAlignment: offeringTextAlignment,
      layout: offeringLayout,
      errorMessageTranslated,
      ratio: offeringRatio,
      ratioFlipped: offeringRatioFlipped,
      imagePosition: offeringImagePosition,
    };
  }

  // TODO - create private static methods and transfer logic from offering domain to here.
}
