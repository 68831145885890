import * as React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import s from './MultiOfferingsTitle.st.css';

export const MultiOfferingsTitle = ({ children, alignment }) => {
  return (
    <div
      {...s('root', {
        alignment,
      })}
    >
      <Text typography="largeTitle">{children}</Text>
    </div>
  );
};
