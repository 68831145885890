import { ImageDto } from '@wix/bookings-uou-domain/dist/src';

export const validMediaGalleryItem = (): ImageDto => ({
  fileName: '2be684_3087b59d45294dd4ae3344c889c2bf2d.png',
  relativeUri: '2be684_3087b59d45294dd4ae3344c889c2bf2d.png',
  width: 900,
  height: 664,
});

export class MediaGalleryItemBuilder {
  private readonly mediaGalleryItem: ImageDto = validMediaGalleryItem();

  build = () => this.mediaGalleryItem;

  withWidth = (width: number) => {
    this.mediaGalleryItem.width = width;
    return this;
  };

  withHeight = (height: number) => {
    this.mediaGalleryItem.height = height;
    return this;
  };

  withFileName = (fileName: string) => {
    this.mediaGalleryItem.fileName = fileName;
    this.mediaGalleryItem.relativeUri = fileName;
    return this;
  };
}
