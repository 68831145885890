const hexToRGBA = (hex, opacity) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

const isColorOverrideNeeded = data => data.opacity === 0 || !!data.opacity;

export const isColor = data => !!data.themeName;
export const overrideSiteColorIfNeeded = (color, siteColors) => {
  //overloadDefaultColor
  if (isColor(color) && isColorOverrideNeeded(color)) {
    const siteColor = siteColors.find(
      ({ reference }) => reference === color.themeName,
    );
    const overloadedColor = {
      reference: color.themeName,
      value: hexToRGBA(siteColor.value, color.opacity),
    };
    return overloadedColor;
  }
  return color;
};
