import * as React from 'react';
import { Divider } from 'wix-ui-tpa/Divider';
import s from './OfferingInfo.st.css';
import { IOfferingViewModel } from '../../domain/offering-view-model-factory';
import { OfferingName } from './OfferingName/OfferingName';
import { OfferingTagLine } from './OfferingTagLine/OfferingTagLine';
import { OfferingDetails } from './OfferingDetails/OfferingDetails';
import { OfferingCTA } from './OfferingCTA/OfferingCTA';
import {
  BiLoggerProps,
  withBiLoggerContext,
} from '../../adapters/reporting/bi-logger/bi-logger-context';
import { WIDGET_BI_REFERRAL } from '../../adapters/reporting/bi-logger/bi.const';

interface OfferingInfoProps {
  offeringViewModel: IOfferingViewModel;
}

class OfferingInfo extends React.PureComponent<
  OfferingInfoProps & BiLoggerProps
> {
  constructor(props) {
    super(props);
    this.logActionButtonClicked = this.logActionButtonClicked.bind(this);
    this.logOfferingNameClicked = this.logOfferingNameClicked.bind(this);
  }

  logOfferingNameClicked() {
    this.logAction(WIDGET_BI_REFERRAL.WIDGET_TITLE);
  }

  logActionButtonClicked() {
    this.logAction(WIDGET_BI_REFERRAL.WIDGET_BUTTON);
  }

  logAction(referralInfo): void {
    const { offeringViewModel, biLoggerDriver } = this.props;
    biLoggerDriver.sendWidgetClick(
      offeringViewModel.id,
      offeringViewModel.type,
      referralInfo,
    );
  }

  render() {
    const { offeringViewModel } = this.props;

    return (
      <div
        data-hook="info-container"
        {...s('root', {
          alignment: offeringViewModel.textAlignment,
        })}
      >
        <OfferingName
          data-hook="tile-title"
          onClick={this.logOfferingNameClicked}
        >
          {offeringViewModel.title}
        </OfferingName>
        {offeringViewModel.tagLine && (
          <OfferingTagLine data-hook="tile-tagline">
            {offeringViewModel.tagLine}
          </OfferingTagLine>
        )}
        {offeringViewModel.dividerVisibility && (
          <Divider data-hook="tile-divider" className={s.divider} />
        )}
        {offeringViewModel.days && (
          <OfferingDetails data-hook="tile-days">
            {offeringViewModel.days}
          </OfferingDetails>
        )}
        {offeringViewModel.startDate && (
          <OfferingDetails data-hook="tile-start-date">
            {offeringViewModel.startDate}
          </OfferingDetails>
        )}
        {offeringViewModel.duration && (
          <OfferingDetails data-hook="tile-duration">
            {offeringViewModel.duration}
          </OfferingDetails>
        )}
        {offeringViewModel.price && (
          <OfferingDetails data-hook="tile-price">
            {offeringViewModel.price}
          </OfferingDetails>
        )}
        {offeringViewModel.action.isVisible && (
          <OfferingCTA
            onClick={this.logActionButtonClicked}
            data-hook="tile-button"
            large={offeringViewModel.action.isLarge}
            secondary={offeringViewModel.action.isSecondary}
            theme={offeringViewModel.action.type}
          >
            {offeringViewModel.action.text}
          </OfferingCTA>
        )}
      </div>
    );
  }
}

export default withBiLoggerContext(OfferingInfo);
