import * as React from 'react';
import OfferingViewImage from './OfferingViewImage/OfferingViewImage';
import OfferingInfo from '../OfferingInfo/OfferingInfo';
import { IOfferingViewModel } from '../../domain/offering-view-model-factory';
import { OfferingListLayoutOptions } from '../../../SharedAppKeys/SettingsKeys';
import { DEFAULT_IMAGE_CONTAINER } from './OfferingView.const';
import s from './OfferingView.st.css';
import classicStyle from './ClassicLayout.st.css';
import overlappingStyle from './OverlappingLayout.st.css';
import {
  BiLoggerProps,
  withBiLoggerContext,
} from '../../adapters/reporting/bi-logger/bi-logger-context';
import { WIDGET_BI_REFERRAL } from '../../adapters/reporting/bi-logger/bi.const';

interface OfferingViewProps {
  offeringViewModel: IOfferingViewModel;
}

interface OfferingViewState {
  shouldUpdateImageDimensions: boolean;
}

class OfferingView extends React.Component<
  OfferingViewProps & BiLoggerProps,
  OfferingViewState
> {
  public static Image;
  private readonly offeringViewRef;
  displayName = 'OfferingView';

  constructor(props) {
    super(props);
    this.offeringViewRef = React.createRef();
    this.state = { shouldUpdateImageDimensions: false };
    this.logViewImageClicked = this.logViewImageClicked.bind(this);
  }

  private get imageElementRef() {
    return this.offeringViewRef.current.firstChild;
  }

  private get infoElementRef() {
    return this.offeringViewRef.current.lastChild;
  }

  private get offeringInfoHeight(): number {
    /* tslint:disable */
    return this.infoElementRef.clientTop * 2 + this.infoElementRef.clientHeight;
    /* tslint:enable */
  }

  private get isOverlappingLayout() {
    return (
      this.props.offeringViewModel.layout ===
      OfferingListLayoutOptions.OVERLAPPING
    );
  }

  private get isImageVisible() {
    const { offeringViewModel } = this.props;
    return offeringViewModel.image.isVisible;
  }

  private get overlappingLayoutMargin(): number {
    return this.offeringInfoHeight * 0.1;
  }

  private setOverlappingOfferingInfoPosition() {
    this.infoElementRef.style.top = `${this.overlappingLayoutMargin}px`;
  }

  private setOverlappingOfferingImageHeight() {
    const imageHeight =
      this.offeringInfoHeight + 2 * this.overlappingLayoutMargin;
    this.imageElementRef.style.height = `${imageHeight}px`;
  }

  private setClassicOfferingImageHeight() {
    this.imageElementRef.style.height = `${this.offeringInfoHeight}px`;
  }

  private setLayoutProperties() {
    if (this.isImageVisible) {
      if (this.isOverlappingLayout) {
        this.setOverlappingOfferingImageHeight();
        this.setOverlappingOfferingInfoPosition();
      } else {
        this.setClassicOfferingImageHeight();
      }
    }
  }

  private getStyle() {
    const { offeringViewModel } = this.props;
    if (this.isOverlappingLayout) {
      return overlappingStyle.$cssStates({
        imagePosition: offeringViewModel.imagePosition,
        isImageHidden: !this.isImageVisible,
      });
    }
    return classicStyle.$cssStates({
      ratio: offeringViewModel.ratio,
      flippedRatio: offeringViewModel.ratioFlipped,
      imagePosition: offeringViewModel.imagePosition,
    });
  }

  private offeringImageDimensions(): { width: number; height: number } {
    if (
      this.offeringViewRef.current &&
      this.offeringViewRef.current.firstChild.getClientRects()[0]
    ) {
      const {
        width,
        height,
      } = this.offeringViewRef.current.firstChild.getClientRects()[0];
      return { width, height };
    }
    return DEFAULT_IMAGE_CONTAINER;
  }

  logViewImageClicked() {
    const { offeringViewModel, biLoggerDriver } = this.props;
    biLoggerDriver.sendWidgetClick(
      offeringViewModel.id,
      offeringViewModel.type,
      WIDGET_BI_REFERRAL.WIDGET_IMAGE,
    );
  }

  componentDidUpdate() {
    this.setLayoutProperties();
  }

  componentDidMount() {
    this.setLayoutProperties();
    this.setState({ shouldUpdateImageDimensions: true });
  }

  render() {
    const { offeringViewModel } = this.props;

    return (
      <div
        ref={this.offeringViewRef}
        data-hook="offering-view"
        {...s('root', {
          layout: offeringViewModel.layout,
        })}
        {...this.getStyle()}
      >
        {this.isImageVisible && (
          <OfferingViewImage
            onClick={this.logViewImageClicked}
            {...this.offeringImageDimensions()}
            imageViewModel={offeringViewModel.image}
          />
        )}
        <OfferingInfo offeringViewModel={offeringViewModel} />
      </div>
    );
  }
}

// function getChildrenObject(children) {
//   return React.Children.toArray(children).reduce((acc, child) => {
//     console.log(child.displayName);
//     switch (child.type.displayName) {
//       case 'OfferingView.Image': {
//         acc.OfferingViewImage = child;
//         break;
//       }
//       default:
//     }
//     return acc;
//   }, {});
// }

export default withBiLoggerContext(OfferingView);
