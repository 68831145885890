const isFontOverrideNeeded = font => !!font.size;

export const isFont = data => !!data.editorKey;
export const overrideSiteFontIfNeeded = (font, siteTextPresets) => {
  if (isFont(font) && isFontOverrideNeeded(font)) {
    const siteFont = siteTextPresets[font.editorKey];
    const overloadedFont = { ...siteFont, ...font };
    return overloadedFont;
  }
  return font;
};
