import * as React from 'react';
import { Button } from 'wix-ui-tpa/Button';
import s from './OfferingCTA.st.css';

export const OfferingCTA = ({
  large,
  secondary,
  theme,
  onClick,
  children,
  ...rest
}) => {
  return (
    <div className={s.buttonWrapper}>
      <Button
        {...s('root', { theme }, rest)}
        size={large ? 'large' : 'medium'}
        priority={secondary ? 'secondary' : 'primary'}
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  );
};
