import { OfferedAsType } from '@wix/bookings-uou-domain/dist/src/offerings/offering.dto';
import { PriceDomain } from '@wix/bookings-uou-domain/dist/src/domains/price-domain';
import { PriceDto } from '@wix/bookings-uou-domain/dist/src/domains/price-dto-factory';
import { DurationDto } from '@wix/bookings-uou-domain/dist/src/domains/duration-dto-factory';
import { DurationDomain } from '@wix/bookings-uou-domain/dist/src/domains/duration-domain';
import { MediaGalleryItemBuilder } from '../../../test/helpers/media-galley-item.builder';
import {
  CatalogOfferingDto,
  OfferingType,
  PaymentType,
} from '@wix/bookings-uou-domain/dist/src';

export const DUMMY_OFFERING_ID = '-1';

const baseDummyOffering = ({ dummyName = '' }): CatalogOfferingDto => {
  return {
    dummy: true,
    id: DUMMY_OFFERING_ID,
    categoryId: '-1',
    order: -1,
    type: OfferingType.DUMMY,
    urlName: null,
    offeredAs: [OfferedAsType.ONE_TIME],
    pricingPlanInfo: null,
    info: {
      name: dummyName,
      description: null,
      tagLine: null,
      images: [
        new MediaGalleryItemBuilder()
          .withFileName('widget-icon.png')
          .withWidth(1000)
          .withHeight(1000)
          .build(),
      ],
    },
    payment: {
      currency: 'USD',
      price: 20,
      isFree: false,
      priceText: '',
      minCharge: 0,
      paymentType: PaymentType.OFFLINE,
    },
    schedulePolicy: {
      displayOnlyNoBookFlow: false,
      maxParticipantsPerOrder: undefined,
      uouHidden: undefined,
      capacity: undefined,
    },
    scheduleHeader: {
      startDate: null,
      endDate: null,
      durationInMinutes: 60,
    },
  };
};

export const createDummyOfferingDto = (): CatalogOfferingDto => {
  return baseDummyOffering({
    dummyName: 'editor-dummy-service.title',
  }) as CatalogOfferingDto;
};

export class OfferingDomain {
  static createDummyOffering(): OfferingDomain {
    return OfferingDomain.fromDto(createDummyOfferingDto());
  }

  private constructor(protected offeringDto: CatalogOfferingDto) {}

  static fromDto(offeringDto: CatalogOfferingDto) {
    return offeringDto ? new OfferingDomain(offeringDto) : null;
  }

  get name() {
    return this.offeringDto.info.name;
  }

  set name(newName: string) {
    this.offeringDto.info.name = newName;
  }

  get type() {
    return this.offeringDto.type;
  }

  get categoryId() {
    return this.offeringDto.categoryId;
  }

  get priceText() {
    const priceDto: PriceDto = {
      ...this.offeringDto.payment,
      displayTextForPlan: this.offeringDto.pricingPlanInfo
        ? this.offeringDto.pricingPlanInfo.displayText
        : '',
    };
    return new PriceDomain(priceDto, this.offeringDto.offeredAs).text;
  }

  durationTextByFormat(formatter) {
    const durationDto: DurationDto = {
      startDate: this.offeringDto.scheduleHeader.startDate,
      durationInMinutes: this.offeringDto.scheduleHeader.durationInMinutes,
    };
    return new DurationDomain(
      this.offeringDto.type,
      durationDto,
    ).getTextByFormat(formatter);
  }

  get pricingPlanInfo() {
    return this.offeringDto.pricingPlanInfo;
  }

  get scheduleHeader() {
    return this.offeringDto.scheduleHeader;
  }

  daysTextByFormat(formatter) {
    if (this.type === OfferingType.GROUP) {
      const offeringDays = this.offeringDto.scheduleHeader.days;
      if (offeringDays.length === 7) {
        return formatter('working-hours.all-week');
      }
      const weekDayKeyPrefix: string =
        offeringDays.length <= 2 ? 'week-day.' : 'short-week-day.';
      return offeringDays
        .map(day => formatter(weekDayKeyPrefix + day))
        .join(', ');
    }
  }

  errorMessageTranslation(formatter) {
    return formatter('tile-service.premium-not-enabled.message');
  }

  get image() {
    const image =
      this.offeringDto.info.images && this.offeringDto.info.images[0];
    return image ? image : null;
  }

  get displayOnlyNoBookFlow() {
    return this.offeringDto.schedulePolicy.displayOnlyNoBookFlow;
  }

  get description() {
    return this.offeringDto.info.description;
  }

  get tagLine() {
    return this.offeringDto.info.tagLine;
  }

  get payment() {
    return this.offeringDto.payment;
  }

  set currency(newCurrency) {
    this.offeringDto.payment.currency = newCurrency;
  }

  get id() {
    return this.offeringDto.id;
  }

  get dummy() {
    return this.offeringDto.dummy;
  }
}
