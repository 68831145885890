import * as React from 'react';
import { OfferingDomain } from '../../domain/offering-domain';
import { OfferingListWidgetDisplayOptions } from '../../business-logic/offering-list-widget-display-options';
import { OfferingViewModelFactory } from '../../domain/offering-view-model-factory';
import { translate, TransProps } from 'react-i18next';
import OfferingView from '../OfferingView/OfferingView';
import s from './MultiOfferings.st.css';
import { MultiOfferingsTitle } from './MultiOfferingsTitle/MultiOfferingsTitle';
import { MultiOfferingsViewModelFactory } from '../../domain/multi-offerings-view-model-factory';
import { CategoryList } from './CategoryList/CategoryList';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain/dist/src/offerings/offering.dto';

interface MultiOfferingsProps extends TransProps {
  offeringsDomain: OfferingDomain[];
  displayOptions: OfferingListWidgetDisplayOptions;
  categories: OfferingCategoryDto[];
}

interface MultiOfferingsState {
  offeringsToDisplay: OfferingDomain[];
  selectedCategoryIndex: number;
}

export const OfferingList = props => {
  const { t, displayOptions, offeringsDomain } = props;
  return offeringsDomain.map((offeringDomain, index) => {
    const offeringViewModel = OfferingViewModelFactory.createOfferingViewModel(
      offeringDomain,
      displayOptions.offeringDisplayOptions,
      t,
    );
    return (
      <OfferingView
        offeringViewModel={offeringViewModel}
        key={`offering-${index}`}
      />
    );
  });
};

class MultiOfferingsComponent extends React.PureComponent<
  MultiOfferingsProps,
  MultiOfferingsState
> {
  constructor(props) {
    super(props);
    this.onCategorySelected = this.onCategorySelected.bind(this);
    this.state = {
      offeringsToDisplay: this.filterOfferingsByCategory(),
      selectedCategoryIndex: 0,
    };
  }

  filterOfferingsByCategory(categoryId?) {
    const { offeringsDomain, categories } = this.props;
    categoryId = categoryId ? categoryId : categories[0].id;
    return offeringsDomain.filter(
      offeringDomain => offeringDomain.categoryId === categoryId,
    );
  }

  onCategorySelected(selectedCategoryIndex) {
    const { categories } = this.props;
    this.setState({
      offeringsToDisplay: this.filterOfferingsByCategory(
        categories[selectedCategoryIndex].id,
      ),
      selectedCategoryIndex,
    });
  }

  render() {
    const { t, displayOptions, categories } = this.props;
    const multiOfferingsViewModel = MultiOfferingsViewModelFactory.createMultiOfferingsViewModel(
      displayOptions.multiOfferingsDisplayOptions,
      categories,
      t,
    );

    return (
      <div
        data-hook="multi-offerings-container"
        {...s('root', { layout: multiOfferingsViewModel.layout })}
      >
        {multiOfferingsViewModel.title.isVisible && (
          <MultiOfferingsTitle
            alignment={multiOfferingsViewModel.title.alignment}
            data-hook="multi-offerings-title"
          >
            {multiOfferingsViewModel.title.text}
          </MultiOfferingsTitle>
        )}

        <div className={s.categoriesContainer}>
          {multiOfferingsViewModel.categories.isVisible && (
            <CategoryList
              categories={categories}
              selectedCategoryIndex={this.state.selectedCategoryIndex}
              categoriesViewModel={multiOfferingsViewModel.categories}
              onCategorySelected={this.onCategorySelected}
            />
          )}
        </div>

        <OfferingList
          displayOptions={displayOptions}
          offeringsDomain={this.state.offeringsToDisplay}
          t={t}
        />
      </div>
    );
  }
}

export const MultiOfferings = translate(null, { wait: true })(
  MultiOfferingsComponent,
);
